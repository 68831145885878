import gql from 'graphql-tag';

export default gql`
  query {
      activeLocationsWeb {
      id
      name_en
      type
      is_active
      city {
        id
        name_en
        is_active
      }
    }
  }
`;
